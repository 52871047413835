import './ContentPageHeader.scss';
import * as React from "react";
import { ArrowSvg } from 'components/svg';
import { ClassableProps, baseClassAnd, childClassClosure } from '@jcharlesworthuk/your-mum-core/dist/functions';
import LinkWrapper from "components/texty/LinkWrapper";


type Props = ClassableProps & {
    title: string;
    backArrowRoute?: string;
}


const baseClass = 'content-page-header';
const childClass = childClassClosure(baseClass);


const ContentPageHeader: React.FunctionComponent<Props> = (props: Props) => {

        return <header className={baseClassAnd(baseClass, props)}>
            { props.backArrowRoute &&
                <LinkWrapper to={props.backArrowRoute} className={childClass('back-button')}><ArrowSvg /></LinkWrapper> }
            {props.title ? <h1 className={childClass('title')}>{props.title}</h1> : <div className={childClass('title')}></div> }
            <LinkWrapper to='/'><img className={childClass('logo')} alt='ModelShade Paint Conversion Charts' src="/assets/fixed/modelshade.svg" /></LinkWrapper>
        </header>
    }

export default ContentPageHeader;