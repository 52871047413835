import React from "react"
import { graphql, Link } from "gatsby"
import "./_CommonShared.scss";
import "./ArticleLayout.scss";
import { Mdx, FileConnection, Site } from "@GraphQLModels";
import MdxBlock from "components/texty/MdxBlock";
import { childClassClosure } from '@jcharlesworthuk/your-mum-core/dist/functions';
import ArticleSubTitleBar from "components/articles/ArticleSubTitleBar";
import SiteFooter from "components/headersfooters/SiteFooter";
import { ArticleWaveSvg } from "components/svg";
import ContentPageHeader from "components/headersfooters/ContentPageHeader";
import PageMeta from "components/texty/PageMeta";
import { getArticleStructuredData } from "@jcharlesworthuk/your-mum-frontend/components/seo/ArticleStructuredData";
import { getHowToStructuredData } from "@jcharlesworthuk/your-mum-frontend/components/seo/HowToStructuredData";
import { mapMdxToArticle, mapMdxToHowTo, mapToWebsiteInformation } from "graphql/graphql-mappers";
import LinkWrapper from "components/texty/LinkWrapper";


type Props = {
  pageContext: {
    id: string,
  };
  data: {
    mdx: Mdx,
    allFile: FileConnection
    site: Site
  }
}


const baseClass = 'article-view';
const childClass = childClassClosure(baseClass);


const ArticleLayout: React.FunctionComponent<Props> = (props: Props) => {
  const article = mapMdxToArticle(props.data.mdx);
  const website = mapToWebsiteInformation(props.data.site.siteMetadata);
  const seoType = props.data.mdx.frontmatter.seoType || 'article';

//  const otherArticles = Array.from(props.data.allFile.edges.filter(x => x.node.childMdx.id !== props.pageContext.id).map(edge => ArticleMetaData.fromFile(edge.node)));
  return (
    <div className={baseClass}>
<PageMeta
        title={article.title}
        description={article.standfirst}
        route={props.data.mdx.fields.route}
        imageUrl={props.data.mdx.frontmatter.mainImage}>
        <meta property="og:type" content="article" />
        { seoType === 'article' && <script className='structured-data-list' type="application/ld+json">{getArticleStructuredData(article, website)}</script>}
        { seoType === 'howto' && <script className='structured-data-list' type="application/ld+json">{getHowToStructuredData(mapMdxToHowTo(article, props.data.mdx), website)}</script>}
      </PageMeta>
      <ArticleWaveSvg className={childClass('waves')} />
      <ContentPageHeader
        className={childClass('header')}
        title=''
        backArrowRoute='/' />
      <MdxBlock mdx={props.data.mdx}
        className={childClass('section', 'no-top-space')}
        showTitle={true}
        subTitle={<ArticleSubTitleBar 
                  timeToRead={props.data.mdx.timeToRead} 
                  date={new Date(props.data.mdx.frontmatter.date)} 
                  subTitle={props.data.mdx.frontmatter.subTitle} />} />
      <aside className={childClass('section')}>
        <div className={childClass('callout-box')}>
          <h2>Looking for the paint converter?</h2>
          <p>Our paint conversion tool will help you convert between over 2000 different paint products</p>
          <div className='cta-container'>
            <LinkWrapper to={'/paint-conversion-chart/'} className='cta'>Paint Converter</LinkWrapper>
          </div>
        </div>
      </aside>
      {/* 
      <section className={childClass('section')}>
        <h3 className={childClass('section-title')}>More Articles from ModelShade</h3>
        <ul className={layoutChildClass('article-list')}>
          {otherArticles.map((article, index) => <li key={index} className={layoutChildClass('article-list-item')}>
            <ArticleLinkCard articleMeta={article} inPanel={false} />
          </li>)}
        </ul>
      </section> */}
      {/* {(() => {
        switch(props.data.mdx.frontmatter.seoType) {
          case 'howto':
            return <script className='structured-data-list' type="application/ld+json" dangerouslySetInnerHTML={{__html: getHowToStructuredData(HowToData.fromNode(thisArticle, props.data.mdx))}}></script>;
          default:
            return <script className='structured-data-list' type="application/ld+json" dangerouslySetInnerHTML={{__html: getArticleStructuredData(thisArticle)}}></script>;
        }
      })()} */}
      <SiteFooter />
    </div>
  )
}

export default ArticleLayout;

export const pageQuery = graphql`
  query ArticleLayoutQuery($id: String) {
      mdx(id: {eq: $id }) {
        id
        fields {
          route
          steps {
            title
            imageAlt
            imageUrl
            description
          }
        }
        timeToRead
        frontmatter {
          title
          subTitle
          mainImage
          standfirst
          date
          seoType
        }
        body
      }
      site {
            siteMetadata {
                title
                siteUrl
                siteLogoUrl
                siteLogoUrlSquare
                shortDescription
                social {
                  twitter {
                      handle,
                      url
                  }
                }
            }
      }
      allFile( filter: { dir: { regex: "/content\/articles/" } } ) {
          edges {
            node {
              childMdx { 
                id
                fields {
                  route
                }
                timeToRead
                frontmatter {
                  title
                  standfirst
                  date
                  mainImage
                }
              }
              name
              relativeDirectory
            }
          }
      } 
  }
  `

