import * as React from 'react';
import "../../../node_modules/@jcharlesworthuk/your-mum-frontend/components/articles/Markdown.scss";
import './MdxBlock.scss';
import { childClassClosure, baseClassAnd } from '@jcharlesworthuk/your-mum-core/dist/functions';
import { Mdx } from '@GraphQLModels';
import LinkWrapper from './LinkWrapper';
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx";

const baseClass = 'markdown';
const childClass = childClassClosure(baseClass);


interface Props {
  className?: string;
  showTitle?: boolean;
  subTitle?: JSX.Element;
  mdx: Mdx;
}


const components = {
  a: LinkWrapper
}


const MdxBlock: React.FunctionComponent<Props> = (props: Props) => {

  return <section className={baseClassAnd(baseClass, props)}>
    {props.showTitle && <h1 className={childClass('main-title')}>{props.mdx.frontmatter.title}</h1>}
    {props.subTitle}
    <MDXProvider components={components}>
      <MDXRenderer>{props.mdx.body}</MDXRenderer>
    </MDXProvider>
  </section >
}
export default MdxBlock;
