import * as React from 'react';
import './ArticleSubTitleBar.scss';
import { childClassClosure } from '@jcharlesworthuk/your-mum-core/dist/functions';

const baseClass = 'article-subtitle-bar';
const childClass = childClassClosure(baseClass);

type Props = {
  subTitle?: string;
  timeToRead?: number
  date?: Date
}

const ArticleSubTitleBar: React.FunctionComponent<Props> = (props: Props) => {

  return <div className={baseClass}>
    <p className={childClass('subtitle')}>{props.subTitle}</p>
    <span className={childClass('date')}>{describeArticleDate(props.date)}</span><span className={childClass('reading-time')}>{props.timeToRead} min read</span>
    </div>
}
export default ArticleSubTitleBar;

export function describeArticleDate(date: Date) {
  return date.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })
}